import { Component, OnInit, Input } from '@angular/core';
import { Router, NavigationExtras } from "@angular/router";

@Component({
  selector: 'app-data-input',
  templateUrl: './data-input.component.html',
  styleUrls: ['./data-input.component.css']
})
export class DataInputComponent implements OnInit {

  constructor(private router: Router) {}

  ngOnInit(): void {
    // var i = 0;
    // for(i=0; i < this.userPara; i++){
    //   this.childs[i] = 1;
    // }
  }

  myVal: number;
  public userHeight: number = 150;
  public userWeight: number = 70;
  public userEthnicity = 'S';
  public userPara: string = '2';
  public userSex = 'M';
  public userEdd : Date = new Date();
  public userBmi: number = 0.0;

  public userName : string = '';
  public userNIC : string = '';

  public childs : number[]; // = [1,2,3,4];

  addMotherData() {
    var fixedConstant = 3478.4;
    var height = (this.userHeight - 162.3) * 7.8;
    var weight = (this.userWeight - 64.3) * 8.7;
    var ethnicity = 0;

    if (this.userEthnicity === 's' || this.userEthnicity === 'S') {
      ethnicity = -186;
    } else if (this.userEthnicity === 'a' || this.userEthnicity === 'A') {
      ethnicity = -127.5;
    } else if (this.userEthnicity === 'e' || this.userEthnicity === 'E') {
      ethnicity = 0;
    } else {
      ethnicity = -65.2;
    }

    var para = 0;

    if (this.userPara === '1') {
      para = 108;
    } else if (this.userPara === '2') {
      para = 148.6;
    } else if (this.userPara === '3') {
      para = 149.9;
    } else if (this.userPara > '3') {
      para = 149.8;
    }

    var sex = 0;

    if (this.userSex === 'M' || this.userSex === 'm') {
      sex = 58.4;
    } else if (this.userSex === 'F' || this.userSex === 'f') {
      sex = -58.4;
    }

    var tow = fixedConstant + height + weight + ethnicity + para + sex;
    this.myVal = tow;

    this.userBmi = (this.userWeight*100*100)/(this.userHeight*this.userHeight);
    // DataChartComponent.

    // myEDD41 = newData.edd + (1000 * 60 * 60 * 24 * 7);
    // myEDD42 = myEDD41 + (1000 * 60 * 60 * 24 * 7);

    // myEDD = newData.edd;
    // myEDD39 = newData.edd - (1000 * 60 * 60 * 24 * 7);
    // myEDD38 = myEDD39 - (1000 * 60 * 60 * 24 * 7);
    // myEDD37 = myEDD38 - (1000 * 60 * 60 * 24 * 7);
    // myEDD36 = myEDD37 - (1000 * 60 * 60 * 24 * 7);
    // myEDD35 = myEDD36 - (1000 * 60 * 60 * 24 * 7);
    // myEDD34 = myEDD35 - (1000 * 60 * 60 * 24 * 7);
    // myEDD33 = myEDD34 - (1000 * 60 * 60 * 24 * 7);
    // myEDD32 = myEDD33 - (1000 * 60 * 60 * 24 * 7);
    // myEDD31 = myEDD32 - (1000 * 60 * 60 * 24 * 7);
    // myEDD30 = myEDD31 - (1000 * 60 * 60 * 24 * 7);
    // myEDD29 = myEDD30 - (1000 * 60 * 60 * 24 * 7);
    // myEDD28 = myEDD29 - (1000 * 60 * 60 * 24 * 7);
    // myEDD27 = myEDD28 - (1000 * 60 * 60 * 24 * 7);
    // myEDD26 = myEDD27 - (1000 * 60 * 60 * 24 * 7);
    // myEDD25 = myEDD26 - (1000 * 60 * 60 * 24 * 7);
    // myEDD24 = myEDD25 - (1000 * 60 * 60 * 24 * 7);

    // this.calculateChart(tow);
    // drawChart(defaultData);



    let navigationExtras: NavigationExtras = {
      queryParams: {
        "tow": this.myVal,
        "edd": this.userEdd,
        "p": this.userPara,
        "w": this.userWeight,
        "h": this.userHeight,
        "e": this.userEthnicity,
        "bmi": this.userBmi,
        "name": this.userName,
        "nic": this.userNIC,
      }
    };
    this.router.navigate(["data-chart"], navigationExtras);

  }
}
