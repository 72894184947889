import { Component, OnInit, Input } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import * as moment from 'moment';

@Component({
  selector: 'app-data-chart',
  templateUrl: './data-chart.component.html',
  styleUrls: ['./data-chart.component.css']
})
export class DataChartComponent implements OnInit {

  public tow: number;
  public userEdd : Date = new Date();

  public cal : Date[] = [];


  public userHeight: number = 150;
  public userWeight: number = 70;
  public userEthnicity = 'i';
  public userPara: string = '2';
  public userSex = 'M';
  public userBmi: number = 0.0;

  public userName: string = '';
  public userNic: string = '';


  public dataChartLabels = [['24', ' ', ' ', ' ', ' '], ['25', ' ', ' ', ' ', ' '], ['26', ' ', ' ', ' ', ' '], ['27', ' ', ' ', ' ', ' '], ['28', ' ', ' ', ' ', ' '], ['29', ' ', ' ', ' ', ' '], ['30', ' ', ' ', ' ', ' '], ['31', ' ', ' ', ' ', ' '], ['32', ' ', ' ', ' ', ' '], ['33', ' ', ' ', ' ', ' '], ['34', ' ', ' ', ' ', ' '], ['35', ' ', ' ', ' ', ' '], ['36', ' ', ' ', ' ', ' '], ['37', ' ', ' ', ' ', ' '], ['38', ' ', 0, 0, 0], ['39', ' ', ' ', ' ', ' '], ['40', ' ', ' ', ' ', ' '], ['41', ' ', ' ', ' ', ' '], ['41', ' ', ' ', ' ', ' ']];

  public eddWeeks : Date[] = [];
  public eddWeeksDates : string  = '';

  constructor(private route: ActivatedRoute) {
    // this.aaa[5] = 10;
    this.route.queryParams.subscribe(params => {
      this.tow = params["tow"];
      this.userEdd = params["edd"];

      this.userPara = params["p"];
      this.userWeight = params["w"];
      this.userHeight = params["h"];
      this.userEthnicity = params["e"];
      this.userBmi = params["bmi"];

      this.userName = params["name"];
      this.userNic = params["nic"];

      let date:Date = new Date(params["edd"]);
      this.userEdd = date;

      let i = 0;
      this.cal[40] = date;
      for(i=39; i>23; i--){
        this.cal[i] = new Date(this.cal[i+1].getTime() - (1000 * 60 * 60 * 24 * 7));
      }
      this.cal[41] = new Date( this.cal[40].getTime() + (1000 * 60 * 60 * 24 * 7) );
      this.cal[42] = new Date( this.cal[41].getTime() + (1000 * 60 * 60 * 24 * 7) );

      // var i = 0;

      for(i=24;i<127;i++){
        this.dataChartLabels[i] = [i, ' ', ' ', ' ', ' '];
      }

      var monthArray = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

      var j = -1;
      // for(i=0; i <19; i++){
      for(i=0; i <127; i++){
        if(i%7){
        }else{
          j=j+1;
          // this.dataChartLabels[i] = [j+24, '' , this.cal[j+24].getDate(), monthArray[this.cal[j+24].getMonth()], this.cal[j+24].getFullYear()];
          this.dataChartLabels[i] = [ '('.toString().concat(this.cal[j+24].getFullYear().toString(),' ' , monthArray[this.cal[j+24].getMonth()], ' ', this.cal[j+24].getDate().toString(), ')   ', (j+24).toString(),'-'), '', '', ''];
        }
        // this.dataChartLabels[i] = [i+24, '' , this.cal[i+24].getDate(), monthArray[this.cal[i+24].getMonth()], this.cal[i+24].getFullYear()];
      }

      // this.dataChartLabels[0] = ['24', '' , this.cal[24].getDate(), monthArray[this.cal[24].getMonth()], this.cal[24].getFullYear()];
      // this.dataChartLabels[1] = ['25', '' , this.cal[25].getDate(), monthArray[this.cal[25].getMonth()], this.cal[25].getFullYear()];
      // this.dataChartLabels[2] = ['26', '' , this.cal[26].getDate(), monthArray[this.cal[26].getMonth()], this.cal[26].getFullYear()];
      // this.dataChartLabels[3] = ['27', '' , this.cal[27].getDate(), monthArray[this.cal[27].getMonth()], this.cal[27].getFullYear()];
      // this.dataChartLabels[4] = ['28', '' , this.cal[28].getDate(), monthArray[this.cal[28].getMonth()], this.cal[28].getFullYear()];
      // this.dataChartLabels[5] = ['29', '' , this.cal[29].getDate(), monthArray[this.cal[29].getMonth()], this.cal[29].getFullYear()];

      // this.dataChartLabels[6] = ['30', '' , this.cal[30].getDate(), monthArray[this.cal[30].getMonth()], this.cal[30].getFullYear()];
      // this.dataChartLabels[7] = ['31', '' , this.cal[31].getDate(), monthArray[this.cal[31].getMonth()], this.cal[31].getFullYear()];
      // this.dataChartLabels[8] = ['32', '' , this.cal[32].getDate(), monthArray[this.cal[32].getMonth()], this.cal[32].getFullYear()];
      // this.dataChartLabels[9] = ['33', '' , this.cal[33].getDate(), monthArray[this.cal[33].getMonth()], this.cal[33].getFullYear()];
      // this.dataChartLabels[10] =['34', '' , this.cal[34].getDate(), monthArray[this.cal[34].getMonth()], this.cal[34].getFullYear()];
      // this.dataChartLabels[11] =['35', '' , this.cal[35].getDate(), monthArray[this.cal[35].getMonth()], this.cal[35].getFullYear()];
      // this.dataChartLabels[12] =['36', '' , this.cal[36].getDate(), monthArray[this.cal[36].getMonth()], this.cal[36].getFullYear()];
      // this.dataChartLabels[13] =['37', '' , this.cal[37].getDate(), monthArray[this.cal[37].getMonth()], this.cal[37].getFullYear()];
      // this.dataChartLabels[14] =['38', '' , this.cal[38].getDate(), monthArray[this.cal[38].getMonth()], this.cal[38].getFullYear()];
      // this.dataChartLabels[15] =['39', '' , this.cal[39].getDate(), monthArray[this.cal[39].getMonth()], this.cal[39].getFullYear()];

      // this.dataChartLabels[16] =['40', '' , this.cal[40].getDate(), monthArray[this.cal[40].getMonth()], this.cal[40].getFullYear()];
      // this.dataChartLabels[17] =['41', '' , this.cal[41].getDate(), monthArray[this.cal[41].getMonth()], this.cal[41].getFullYear()];
      // this.dataChartLabels[18] =['42', '' , this.cal[42].getDate(), monthArray[this.cal[42].getMonth()], this.cal[42].getFullYear()];

    });
    this.calculateChart(this.tow);
  }

  ngOnInit(): void {  }

  public dataChartType = 'line';
  public dataChartLegend = true;
  public dataChartData;
  // public dataChartData = [{
  //     data: [65, 59, 80, 81, 56, 55, 40],
  //     label: 'Series A'
  //   },
  //   {
  //     data: [28, 48, 40, 19, 86, 27, 90],
  //     label: 'Series B'
  //   }
  // ];

  public dataChartOptions = {
    scaleShowVerticalLines: false,
    responsive: true,
    maintainAspectRatio: false,
    title: {
      display: true,
	  fontSize: 40,
      text: 'Customized Growth Chart'
    },
    scales: {
      xAxes: [{
        ticks: {
          // autoSkip: true,
          maxRotation: 90,
          minRotation: 90,
          userCallback: function(item, index) {
              // if (index%7) return "";
              // return item;
              return (index%7) ? "" : item;   // Make the "" to null for distinct lines
          },
          drawTicks: true,
          autoSkip: false,
			fontSize: 20
        },
        display: true
    }],
      yAxes: [{
        type: 'linear', // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
        display: true,
        ticks: {
          beginAtZero: true,
          // steps: 10,
          // stepValue: 5,
          stepSize: 100,
          max: 5000,
		  fontSize: 20,
        },
        position: 'right',
        id: 'y-axis-1',

        // grid line settings
        gridLines: {
          drawOnChartArea: true, // only want the grid lines for one axis to show up
        },
        scaleLabel: {
          display: true,
          labelString: 'Weight based on scan (g)',
		  fontSize: 30
        },
      }, {
        type: 'linear', // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
        display: true,
        ticks: {
          //beginAtZero: true,
          // steps: 11,
          // stepValue: 2,
          // max: 44
          min: 22,
          max: 44,
          stepSize: 2,
		  fontSize: 20,
        },
        scaleLabel: {
          display: true,
          labelString: 'Fundal height (cm)',
		  fontSize: 30,
        },
        position: 'left',
        id: 'y-axis-2',

        // grid line settings
        gridLines: {
          drawOnChartArea: false, // only want the grid lines for one axis to show up
        },
      }],
    }
  };

  calculateChart(tow : number) {
    const CONST_TOW = 3453.05;

    var i = 0;
    var x = 0;

    var  data3 = []; var data10 = []; var data25 = []; var data50 = []; var data75 = []; var data90 = []; var data97 = [];

    var standardDeviation = 13.229; // Standard Deviation

    var localParam1 = tow / 3705; // Local Parameters that is based on TOW, Check where 3705 came?
    var localParam2 = (standardDeviation / 100) * localParam1; // Local parameter that is based on TOW and local paramter 1

    var z = [];
    z[1] = -2.3263479;
    z[3] = -1.8807936;
    z[5] = -1.6448536;
    z[10] = -1.2815516;
    z[25] = -0.6744898;

    // var gestationalAge = [24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42];
    var gestationalAge = [];

    var multipliedFor = [];

    for (i = 0; i < 127; i++) {
      gestationalAge[i] = 24 + (i / 7);
    // for (i = 0; i < 19; i++) {
      multipliedFor[3] = localParam1 + localParam2 * z[3] * localParam1;
      multipliedFor[10] = localParam1 + localParam2 * z[10] * localParam1;
      multipliedFor[25] = localParam1 + localParam2 * z[25] * localParam1;
      multipliedFor[50] = localParam1;
      multipliedFor[75] = localParam1 - localParam2 * z[25] * localParam1;
      multipliedFor[90] = localParam1 - localParam2 * z[10] * localParam1;
      multipliedFor[97] = localParam1 - localParam2 * z[3] * localParam1;


      x = Math.exp(0.578 + (0.332 * (gestationalAge[i] + 0.5)) - (0.00354 * (gestationalAge[i] + 0.5)) * (gestationalAge[i] + 0.5));
      data3[i] = x * multipliedFor[3];
      data10[i] = x * multipliedFor[10];
      data25[i] = x * multipliedFor[25];
      data50[i] = x * multipliedFor[50];
      data75[i] = x * multipliedFor[75];
      data90[i] = x * multipliedFor[90];
      data97[i] = x * multipliedFor[97];
    }


    this. dataChartData = [
      {
        data: data3,
        label: "3rd centile line",
        borderColor: "#3e95cd",
        // borderDash: [5, 5],
        fill: false,
        // borderWidth: 1,
        // pointRadius: 2,
        // pointBorderWidth: 0,
      }, {
        data: data10,
        label: "10th centile line",
        borderColor: "#8e5e42",
        fill: false,
        // borderWidth: 1,
        // pointRadius: 2,
        // pointBorderWidth: 0,
      }, {
        data: data50,
        label: "50th centile line",
        borderColor: "#000000",
        fill: false,
        // borderWidth: 1,
        // pointBackgroundColor: "8e5e42",
        // // pointBorderColor: "rgba(75,192,192,1)",
        // // pointBackgroundColor: "#fff",
        // pointBorderWidth: 0,
        // // pointHoverRadius: 0,
        // // pointHoverBackgroundColor: "rgba(75,192,192,1)",
        // // pointHoverBorderColor: "rgba(220,220,220,1)",
        // // pointHoverBorderWidth: 0,
        // // lineWidth: 100,
        // pointRadius: 1,
        // // pointHitRadius: 0,

      }, {
        data: data90,
        label: "90th centile line",
        borderColor: "#8e5ea2",
        fill: false,
        // borderWidth: 1,
        // pointRadius: 1,
        // pointBackgroundColor: "8e5e42",
        // pointBorderWidth: 0,
      }, {
        data: data97,
        label: "97th centile line",
        borderColor: "#8e5ea2",
        fill: false,
        // borderWidth: 1,
        // pointRadius: 1,
        // pointBackgroundColor: "8e5e42",
        // pointBorderWidth: 0,
      }
    ];
  }
}
