<div class="chart-container">
  <div class="mother-data-area">
    <div style="font-size: 20px;"><b>Mothers Details:</b> {{userName}} ({{userNic}})</div>
    <div style="font-size: 10px;">.</div>
    <div style="font-size: 20px;">Parity: {{userPara}},  Meternal height (cm): {{userHeight}},  Booking weight (Kg): {{userWeight}}</div>
    <div style="font-size: 1px;">.</div>
    <div style="font-size: 20px;">Body Mass Index: {{userBmi | number : '1.2-2' }}</div>
  </div>

  <!-- <div><b>TOW:</b> {{tow}}</div>
  <div><b>EDD:</b> {{userEdd | date}}</div> -->
  <!-- <div>----</div>
  <div>----</div>
  <div>{{userEdd}}</div>
  <div>----</div>
  <div>39: {{myEDD39}}</div>
  <div>38: {{myEDD38}}</div>
  <div>37: {{myEDD37}}</div>
  <div>36: {{myEDD36}}</div>
  <div>35: {{myEDD35}}</div>
  <div>34: {{myEDD34}}</div>
  <div>33: {{myEDD33}}</div>
  <div>32: {{myEDD32}}</div>
  <div>31: {{myEDD31}}</div>
  <div>30: {{myEDD30}}</div>
  <div>29: {{myEDD29}}</div>
  <div>28: {{myEDD28}}</div>
  <div>27: {{myEDD27}}</div>
  <div>26: {{myEDD26}}</div>
  <div>25: {{myEDD25}}</div>
  <div>24: {{myEDD24}}</div> -->

  <!-- <div>{{myEDD39}}</div>
  <div>{{myEDD38}}</div>
  <div>This is {{eddWeeks[39]}}</div> -->

  <canvas class="my-chart" baseChart
    [datasets]="dataChartData"
    [labels]="dataChartLabels"
    [chartType]="dataChartType"
    [options]="dataChartOptions"
    >

    <!-- [legend]="dataChartLegend" -->
  </canvas>

  <div class="form-row">
    <!-- <button type="button" class="btn btn-primary btn-block mb-3 btn-print" (click)="addMotherData()">Print</button> -->
    <!-- <button type="button" class="btn btn-primary btn-block mb-3 btn-generate" routerLink="/data-chart">Generate Chart</button> -->
  </div>

</div>
