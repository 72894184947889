<div class="container">
  <ul class="nav nav-tabs">
    <li class="nav-item">
      <a class="nav-link" routerLink="/data-input">Mother's Data</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" routerLink="/data-chart">Charts</a>
    </li>
    <!-- <li class="nav-item">
      <a class="nav-link" routerLink="/radar-chart">Print</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" routerLink="/pie-chart">Other</a>
    </li> -->
  </ul>
  <div>
      <router-outlet></router-outlet>
  </div>
</div>

<script src="node_modules/chart.js/src/chart.js"></script>
<router-outlet></router-outlet>
