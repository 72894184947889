<div class="main-layout-data-input">
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <h2 class="font-weight-bold text-dark m-2 text-center">Details of the mother</h2>

        <!-- <hr> -->

        <div class="form-row">
          <div class="form-group col">
            <label for="inputCountry">NIC/Passport #</label>
            <input type="text" class="form-control" id="inputName" [(ngModel)]="userNIC">
          </div>
          <div class="form-group col">
            <label for="inputCountry">Name</label>
            <input type="text" class="form-control" id="inputName" [(ngModel)]="userName">
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col">
            <label for="inputCountry">Height</label>
            <input type="text" class="form-control" id="inputCountry" [(ngModel)]="userHeight">
          </div>
          <div class="form-group col">
            <label for="inputLineColor">Weight</label>
            <input type="text" class="form-control" id="inputLineColor" [(ngModel)]="userWeight">
          </div>
          <div class="form-group col">
            <label for="inputLineColor">Ethnicity</label>
            <select type="text" class="form-control" id="inputLineColor" [(ngModel)]="userEthnicity">
              <option value="S">South Asian</option>
              <option value="E">European</option>
              <option value="A">African</option>
              <option value="x">Other</option>
            </select>
          </div>
          <div class="form-group col">
            <label for="inputLineColor">Parity</label>
            <select type="text" class="form-control" id="inputLineColor" [(ngModel)]="userPara">
              <option value="0">0</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4 and above</option>
            </select>
          </div>
          <div class="form-group col">
            <label for="inputLineColor">Sex</label>
            <select type="text" class="form-control" id="inputLineColor" [(ngModel)]="userSex">
              <option value="M">Male</option>
              <option value="F">Female</option>
            </select>
          </div>

          <div class="form-group">
            <label>EDD</label>
            <input type="date" name="bday" max="3000-12-31" min="2020-01-01" class="form-control" [(ngModel)]="userEdd">
          </div>
          <!-- <div class="form-group col">
                      <label for="inputLineColor">My Val</label>
                      <input type="text" class="form-control" id="input" [(ngModel)]="myVal"/>
                  </div> -->
        </div>
        <!-- <div class="form-row">
          <label for="inputLineColor"><b>TOW is : </b> {{myVal}}</label>
        </div>
        <div class="form-row">
          <label for="inputLineColor"><b>EDD is : </b> {{userEdd}}</label>
        </div> -->

        <hr>

        <!-- <ul>
          <div *ngIf="userPara !== '0'">
            <div class="form-row" *ngFor="let child of childs;">
              {{ child }}
              <div class="form-group col">
                <label for="inputCountry">Child 1: Name</label>
                <input type="text" class="form-control" id="inputName" [(ngModel)]="userNIC">
              </div>

              <div class="form-group col">
                <label for="inputCountry">`Sex</label>
                <select type="text" class="form-control" id="inputLineColor" [(ngModel)]="userNIC">
                  <option value="M">Male</option>
                  <option value="F">Female</option>
                </select>
              </div>

              <div class="form-group col">
                <label for="inputLineColor">Birth Weeks</label>
                <select type="text" class="form-control" id="inputLineColor" [(ngModel)]="userPara">
                  <option value="24">24</option>  <option value="25">25</option>  <option value="26">26</option>  <option value="27">27</option>
                  <option value="28">28</option>  <option value="29">29</option>  <option value="30">30</option>  <option value="31">31</option>
                  <option value="32">32</option>  <option value="33">33</option>  <option value="34">34</option>  <option value="35">35</option>
                  <option value="36">36</option>  <option value="37">37</option>  <option value="38">38</option>  <option value="39">39</option>
                  <option value="40">40</option>  <option value="41">41</option>  <option value="42">42</option>
                </select>
              </div>

              <div class="form-group col">
                <label for="inputLineColor">Birth Days</label>
                <select type="text" class="form-control" id="inputLineColor" [(ngModel)]="userPara">
                  <option value="0">0</option>  <option value="1">1</option>  <option value="2">2</option>  <option value="3">3</option>
                  <option value="4">4</option>  <option value="5">5</option>  <option value="6">6</option>
                </select>
              </div>

              <div class="form-group col">
                <label for="inputCountry">Birth weight (g)</label>
                <input type="text" class="form-control" id="inputName" [(ngModel)]="userNIC">
              </div>
            </div>
          </div>
        </ul>


        <div *ngIf="userPara !== '0'">
          <div class="form-row" *ngIf="userPara == '1' || userPara == '2' || userPara == '3'">

            <div class="form-group col">
              <label for="inputCountry">Child 1: Name</label>
              <input type="text" class="form-control" id="inputName" [(ngModel)]="userNIC">
            </div>

            <div class="form-group col">
              <label for="inputCountry">`Sex</label>
              <select type="text" class="form-control" id="inputLineColor" [(ngModel)]="userNIC">
                <option value="M">Male</option>
                <option value="F">Female</option>
              </select>
            </div>

            <div class="form-group col">
              <label for="inputLineColor">Birth Weeks</label>
              <select type="text" class="form-control" id="inputLineColor" [(ngModel)]="userPara">
                <option value="24">24</option>  <option value="25">25</option>  <option value="26">26</option>  <option value="27">27</option>
                <option value="28">28</option>  <option value="29">29</option>  <option value="30">30</option>  <option value="31">31</option>
                <option value="32">32</option>  <option value="33">33</option>  <option value="34">34</option>  <option value="35">35</option>
                <option value="36">36</option>  <option value="37">37</option>  <option value="38">38</option>  <option value="39">39</option>
                <option value="40">40</option>  <option value="41">41</option>  <option value="42">42</option>
              </select>
            </div>

            <div class="form-group col">
              <label for="inputLineColor">Birth Days</label>
              <select type="text" class="form-control" id="inputLineColor" [(ngModel)]="userPara">
                <option value="0">0</option>  <option value="1">1</option>  <option value="2">2</option>  <option value="3">3</option>
                <option value="4">4</option>  <option value="5">5</option>  <option value="6">6</option>
              </select>
            </div>

            <div class="form-group col">
              <label for="inputCountry">Birth weight (g)</label>
              <input type="text" class="form-control" id="inputName" [(ngModel)]="userNIC">
            </div>

          </div>
        </div> -->

        <div class="form-row">
          <button type="button" class="btn btn-primary btn-block mb-3 btn-generate" (click)="addMotherData()">Generate Chart</button>
          <!-- <button type="button" class="btn btn-primary btn-block mb-3 btn-generate" routerLink="/data-chart">Generate Chart</button> -->
        </div>
      </div>
    </div>
  </div>
</div>
